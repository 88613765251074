var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_title_contain"},[_c('header',{staticClass:"other_page_head"},[_c('div',{staticClass:"common_warp header_wrap"},[_c('div',{staticClass:"icon",on:{"click":() => {
              _vm.$router.push('/home')
            }}},[_c('img',{attrs:{"src":require("../assets/eamaster_logo_title_index.png"),"alt":""}})]),_c('div',{staticClass:"menu index_page_menu"},[_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/more')
              }}},[_vm._v(" 全部活动 ")]),_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/about')
              }}},[_vm._v(" 关于我们 ")]),_c('a-input-search',{staticClass:"input_search",attrs:{"placeholder":"夏校、机器人、耶鲁、纽约…","enter-button":"搜索","size":"large","maxLength":100},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('a-icon',{style:({ color: '#cdcdcd' }),attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1),_c('div',{staticClass:"language other_page_language"},[_c('span',{staticClass:"pointer"},[_vm._v("中文")]),_vm._v(" "),_c('span',{staticClass:"line"},[_vm._v("|")]),_c('span',{staticClass:"pointer",on:{"click":() => {
                _vm.$router.push('english')
              }}},[_vm._v(" English")])])])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":require("@/assets/pic_404@2x.png"),"alt":""}}),_c('p',[_vm._v("您访问的页面不存在")])])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <div class="page_title_contain">
      <header class="other_page_head">
        <div class="common_warp header_wrap">
          <div
            class="icon"
            @click="
              () => {
                $router.push('/home')
              }
            "
          >
            <img src="../assets/eamaster_logo_title_index.png" alt="" />
          </div>
          <div class="menu index_page_menu">
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/more')
                }
              "
            >
              全部活动
            </p>
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/about')
                }
              "
            >
              关于我们
            </p>
            <a-input-search
              class="input_search"
              placeholder="夏校、机器人、耶鲁、纽约…"
              enter-button="搜索"
              size="large"
              v-model="keyword"
              :maxLength="100"
              @search="onSearch"
            >
              <a-icon
                slot="prefix"
                type="search"
                :style="{ color: '#cdcdcd' }"
              />
            </a-input-search>
          </div>
          <div class="language other_page_language">
            <span class="pointer">中文</span> <span class="line">|</span
            ><span
              class="pointer"
              @click="
                () => {
                  $router.push('english')
                }
              "
            >
              English</span
            >
          </div>
        </div>
      </header>
      <main>
        <div class="imgbox">
          <img src="@/assets/pic_404@2x.png" alt="" />
          <p>您访问的页面不存在</p>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
    }
  },
  methods: {
    onSearch() {
      this.$router.push({
        params: {
          keyword: this.keyword,
        },
        name: 'more',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page_title_contain {
  background-color: #fff;
  .common_warp,
  .slogan_image {
    position: relative;
    z-index: 1;
  }

  header .slogan_wrap {
    position: absolute;
    z-index: 0;
  }
  header .menu .menu_item {
    color: #333333;
  }
}
main {
  width: 100%;
  height: 645px;
  background: #f6f7f7;
  .imgbox {
    width: 470px;
    margin: 0 auto;
    margin-top: 180px;
    img {
      width: 100%;
      height: 217px;
      margin-bottom: 24px;
    }
    p {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cdcdcd;
      line-height: 42px;
      text-align: center;
    }
  }
}
</style>
